import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Banner = () => (
  <div className="hero-banner" style={{ "background-color": "#091031" }}>
    <div className="container">
      <div className="hero-banner-content privacy-hero text-center">
        <h2 className="text-white text-uppercase font-weight-bold m-0">
          Quandary Consulting Group Privacy Policy
        </h2>
      </div>
    </div>
  </div>
)

class PrivacyPage extends React.Component {
  render() {
    return (
      <Layout banner={<Banner />} location={this.props.location}>
        <Seo
          title="Privacy Policy | Quandary Consulting Group"
          description="Expand your bottom line. Exceed customer expectations. Experience efficiency like never before.Choose Quandary to guide your workflow optimization."
        />
        <div className="container my-5">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <p>
                This Privacy Policy describes how your personal information is
                collected, used, and shared when you visit or make a purchase
                from{" "}
                <a aria-label="link" href="https://quandarycg.com/">
                  Quandary Consulting Group
                </a>{" "}
                (the “Site”).
              </p>
              <p className="h4">PERSONAL INFORMATION WE COLLECT</p>
              <p>
                When you visit the Site, we automatically collect certain
                information about your device, including information about your
                web browser, IP address, time zone, and some of the cookies that
                are installed on your device. Additionally, as you browse the
                Site, we collect information about the individual web pages or
                products that you view, what websites or search terms referred
                you to the Site, and information about how you interact with the
                Site. We refer to this automatically-collected information as
                “Device Information.”
              </p>
              <p>
                We collect Device information using the following technologies:
              </p>
              <ul>
                <li>
                  “Log files” track actions occurring on the Site, and collect
                  data including your IP address, browser type, Internet service
                  provider, referring/exit pages, and date/time stamps.
                </li>
                <li>
                  “Web beacons,” “tags,” and “pixels” are electronic files used
                  to record information about how you browse the Site.
                </li>
              </ul>
              <p>
                When we talk about “Personal Information” in this Privacy
                Policy, we are talking about Device Information.
              </p>
              <p className="h4">HOW DO WE USE YOUR PERSONAL INFORMATION?</p>
              <p>
                We use the Device Information that we collect to help us screen
                for potential risk and fraud (in particular, your IP address),
                and more generally to improve and optimize our Site (for
                example, by generating analytics about how our customers browse
                and interact with the Site, and to assess the success of our
                marketing and advertising campaigns). We also run retargeting
                campaigns using information provided on our website.
              </p>
              <p className="h4">SHARING YOUR PERSONAL INFORMATION</p>
              <p>
                We share your Personal Information with third parties to help us
                use your Personal Information, as described above. For example,
                we also use Google Analytics to help us understand how our
                customers use the Site--you can read more about how Google uses
                your Personal Information here:{" "}
                <a
                  aria-label="link"
                  href="https://www.google.com/intl/en/policies/privacy/"
                >
                  https://www.google.com/intl/en/policies/privacy/
                </a>
                . You can also opt-out of Google Analytics here:{" "}
                <a
                  aria-label="link"
                  href="https://tools.google.com/dlpage/gaoptout"
                >
                  https://tools.google.com/dlpage/gaoptout
                </a>
                .
              </p>
              <p>
                Finally, we may also share your Personal Information to comply
                with applicable laws and regulations, to respond to a subpoena,
                search warrant or other lawful request for information we
                receive, or to otherwise protect our rights.
              </p>
              <p className="h4">BEHAVIOURAL ADVERTISING</p>
              <p>
                As described above, we use your Personal Information to provide
                you with targeted advertisements or marketing communications we
                believe may be of interest to you. For more information about
                how targeted advertising works, you can visit the Network
                Advertising Initiative’s (“NAI”) educational page at{" "}
                <a
                  aria-label="link"
                  href="https://www.networkadvertising.org/understanding-online-advertising/how-does-it-work"
                >
                  www.networkadvertising.org/understanding-online-advertising/how-does-it-work
                </a>
              </p>
              <p>You can opt out of targeted advertising by:</p>
              <p>
                LinkedIn -{" "}
                <a
                  aria-label="link"
                  href="https://www.linkedin.com/psettings/guest-controls"
                >
                  https://www.linkedin.com/psettings/guest-controls
                </a>
              </p>
              <p>
                Additionally, you can opt out of some of these services by
                visiting the Digital Advertising Alliance’s opt-out portal at:{" "}
                <a aria-label="link" href="http://optout.aboutads.info/">
                  www.optout.aboutads.info/
                </a>
                .
              </p>
              <p className="h4">DO NOT TRACK</p>
              <p>
                Please note that we do not alter our Site’s data collection and
                use practices when we see a Do Not Track signal from your
                browser.
              </p>
              <p className="h4">YOUR RIGHTS</p>
              <p>
                If you are a European resident, you have the right to access
                personal information we hold about you and to ask that your
                personal information be corrected, updated, or deleted. If you
                would like to exercise this right, please contact us through the
                contact information below.
              </p>
              <p>
                Additionally, if you are a European resident we note that we are
                processing your information in order to fulfill contracts we
                might have with you (for example if you make an order through
                the Site), or otherwise to pursue our legitimate business
                interests listed above. Additionally, please note that your
                information will be transferred outside of Europe, including to
                Canada and the United States.
              </p>
              <p>
                <a aria-label="link" href="/california-privacy">
                  Information for California Residents (CCPA).
                </a>
              </p>
              <p className="h4">CHANGES</p>
              <p>
                We may update this privacy policy from time to time in order to
                reflect, for example, changes to our practices or for other
                operational, legal or regulatory reasons.
              </p>
              <p className="h4">CONTACT US</p>
              <p>
                For more information about our privacy practices, if you have
                questions, or if you would like to make a complaint, please
                contact us by e-mail at{" "}
                <a aria-label="link" href="mailto:info@quandarycg.com">
                  info@quandarycg.com
                </a>{" "}
                or by mail using the details provided below:
              </p>
              <p className="mb-0">Kevin Shuler</p>
              <p>
                4980 Harlan Str.
                <br /> Denver, C0 80212
                <br /> United States
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default PrivacyPage
